import { useState, useEffect, useRef } from 'react';
import API from '../functions/api';
import { Formik, Form } from 'formik';
import cn from 'classnames';
import Spinner from '../audi-ui-components/Spinner';
import FormField from '../components/FormField';
import Button from '../audi-ui-components/Button';
import DealerByPostcodeModule from '../components/DealerByPostcodeModule';
import Disclaimer from '../components/Disclaimer';
import { PriceDropdown } from '../components/PriceTable';
import PostcodePrice from '../components/PostcodePrice';
import ExteriorOptions from '../components/ExteriorOptions';
import PaintPicker, { paintMap } from '../components/PaintPicker';
import StandardEquipment from '../components/StandardEquipment';
import HeroCarousel from '../components/HeroCarousel';
import Section from '../components/Section';
import { validate, validationObj } from '../functions/validation';
import { formatMobile } from '../functions/textFormatting';
import queryString from 'query-string';
import _find from 'lodash/find';
import _intersection from 'lodash/intersection';

const TODAY = new Date();
const BREAKPOINT = 900;

const Reservation = ({ isIpad, location }) => {
  
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobile(window.innerWidth < BREAKPOINT);
    };
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  
  useEffect(()=>{
    API.get(
      `${process.env.PUBLIC_PATH}config/q4etron2025.json`
    ).then((response) => {
      // console.log(response.data);
      setConfig(response.data);
      setIsLoadingConfig(false);
    }).catch((error) => {
      console.error(error);
    });
  }, []);
  
  const controllerRef = useRef(null);
  
  const [config, setConfig] = useState({});
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  
  const [prices, setPrices] = useState(false);
  const [isFetchingPrices, setIsFetchingPrices] = useState(false);
  const [showPrices, setShowPrices] = useState(false);
  
  const [submitted, setSubmitted] = useState(false);
  const [submittedPayload, setSubmittedPayload] = useState(null);
  const [payloadImg, setPayloadImg] = useState("");
  
  const validateForm = (values) => {
    return validate(values, validationObj, {fullMessages: false});
  }
  
  const submitForm = (values, formikProps) => {
    let query = queryString.parse(location.search);
    let mc = values.variant + values.driveType;
    let variantObj = _find(config.variants, {code: values.variant});
    let driveTypeObj = _find(config.driveTypes, {code: values.driveType});
    let payload = {
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      email: values.email,
      mobile: values.mobile,
      postcode: values.postcode,
      preferredDealer: values.dealer?.dealerName,
      preferredDealerId: values.dealerId,
      contactRegardingModel: values.contactRegardingModel ? 1 : 0,
      marketingOptIn: values.marketingOptIn ? 1 : 0,
      imageUrl: payloadImg,
      modelName: getModelName({variant: values.variant, driveType: values.driveType}),
      shortModelName: getModelName({variant: values.variant, driveType: values.driveType}, true),
      modelYear: config.myNumber.toString(),
      driveAwayPrice: prices.VehicleDriveAwayPrice,
      utmsource: query.utm_source || "",
      utmmedium: query.utm_medium || "",
      utmcampaign: query.utm_campaign || "",
      utmcontent: query.utm_content || "",
      vehicleConfiguration: JSON.stringify({
        modelCode: mc + "/" + config.my,
        shape: variantObj.name,
        driveType: driveTypeObj.name,
        exteriorColour: paintMap[values.extColour],
        exteriorColourCode: values.extColour,
        interiorColour: paintMap[values.interior],
        interiorColourCode: values.interior,
        options: values.options.map((code) => {
          let opt = config.options[code];
          if (typeof opt.price === "object") {
            opt.price = opt.price[mc];
          }
          if (typeof opt.priceLCT === "object") {
            opt.priceLCT = opt.priceLCT[mc];
          }
          return opt;
        })
      })
    };
    // console.log(payload);
    API.post(
      `${process.env.RAZZLE_API_VTP}/1/reservations`,
      payload
    ).then((response) => {
      // console.log(response);
      setSubmitted(true);
      setSubmittedPayload(payload);
      window.scrollTo(0,0);
      // GTM
      window.dataLayer.push({
        'event': 'form',
        'eventAction': 'submitSuccess',
        'eventLabel': 'q4-reservation',
        'bodyStyle': variantObj.name,
        'driveType': driveTypeObj.name,
        'packages': values.options.length > 0 ? values.options.map(code => config.options[code].name).join(", ") : "",
        'colour': paintMap[values.extColour],
        'interior': paintMap[values.interior],
        'postcode': values.postcode,
        'preferredDealer': values.dealer?.dealerName
      });
      if (isIpad) {
        setTimeout(()=>{
          window.location.reload();
        }, 60000);
      }
    }).catch((error) => {
      console.error(error);
    });
  }
  
  const scrollToError = () => {
    setTimeout(() => {
      let el = document.querySelector(".is-invalid");
      if (el) {
        el.scrollIntoView({behavior: "smooth", block: "center"});
      }
    }, 1000);
  }
  
  const onChangeModel = (values, setFieldValue) => {
    // check that options are valid for selected model, reset if not
    let mc = values.variant + values.driveType;
    let validInt = values.interior;
    if (config.interiors[mc].indexOf(validInt) === -1) {
      validInt = config.interiors[mc][0];
      setFieldValue("interior", validInt);
    }
    let validOpts = values.options;
    if (values.options.length > 0) {
      validOpts = _intersection(values.options, config.options[mc]);
      setFieldValue("options", validOpts);
    }
    if (values.state) {
      getPrice(Object.assign({}, values, {interior: validInt, options: validOpts}));
    }
  }
  
  const getPrice = (values) => {
    let {state, variant, driveType, extColour} = values;
    if (state && variant && driveType) {
      if (controllerRef.current !== null) {controllerRef.current.abort();}
      controllerRef.current = new AbortController();
      setIsFetchingPrices(true);
      let mc = variant + driveType;
      let optTotal = config.extColours[extColour].price;
      if (values.options?.length > 0) {
        for (let i=0; i<values.options.length; i++) {
          let opt = config.options[values.options[i]];
          // console.log(opt);
          if (typeof opt.price === "object") {
            optTotal += opt.price[mc];
          } else {
            optTotal += opt.price;
          }
        }
        optTotal = parseInt(optTotal);
      }
      API.get(
        `${process.env.RAZZLE_API_MYAUDI}/2/legacy/GetPrice?state=${state}&modelCode=${mc + "/" + config.my}&optionsprice=${optTotal}`,
        {signal: controllerRef.current.signal}
      ).then((response) => {
        setPrices(response.data?.data);
        setIsFetchingPrices(false);
        controllerRef.current = null;
        // GTM
        window.dataLayer.push({
          'event': 'Q4 Reservation',
          'eventAction': 'Price Estimate',
          'postcode': values.postcode,
          'estimatedPrice': parseFloat(response.data?.data?.VehicleDriveAwayPrice?.replace("$","").replace(",",""))
        });
      }).catch((error) => {
        console.error(error);
        setIsFetchingPrices(false);
        controllerRef.current = null;
      });
    }
  }
  
  const getVariant = (code) => {
    return _find(config.variants, {code: code});
  }
  
  const getDriveType = (code) => {
    return _find(config.driveTypes, {code: code});
  }
  
  const getModelName = (values, short) => {
    if (!values) { return ""; }
    if (values.variant && values.driveType) {
      if (short) {
        return config.engines[values.variant + values.driveType].modelShortName;
      }
      return config.engines[values.variant + values.driveType].modelName;
    }
    return config.name;
  }
  
  if (isLoadingConfig) {
    return (
      <div className="spinner-wrapper placeholder" style={{minHeight:"300px"}}><Spinner isContinuous /></div>
    );
  }
  
  if (submitted && submittedPayload !== null) {
    return (
      <div className="app-outer">
        <div className="aui-color-black aui-color-text-light py-3">
          <div className="app-inner aui-headline-3">Your {config.name} configuration has been submitted</div>
        </div>
        <div className="app-inner position-relative py-5">
          <p className="aui-headline-4 mb-4">Thank you for your interest in the {submittedPayload.modelName}.</p>
          <p className="text-center"><img src={submittedPayload.imageUrl} /></p>
          <p className="mb-5">A member of the {submittedPayload.preferredDealer} team will contact you shortly to finalise your reservation and to discuss next steps.</p>
          {!isIpad && <p>
            <Button variant="secondary" className="mb-2 me-2" href="https://audi.com.au">Back to home</Button>
            <Button variant="primary" className="mb-2" href={config.brandUrl}>Back to {config.name}</Button>
          </p>}
          {isIpad && <Button variant="secondary" onClick={()=>{ window.location.reload(); }}>Reset</Button>}
        </div>
      </div>
    );
  }
  
  return (
    <Formik onSubmit={submitForm} validate={validateForm} initialValues={config.initialValues}>
      {(formikProps) => (
        <Form>
          <div className="app-outer">
          <div className="aui-color-black aui-color-text-light py-3">
            <div className="app-inner aui-headline-3">Configure your {config.name}</div>
          </div>
          <div className="app-inner position-relative">
            {formikProps.isSubmitting && <div className="spinner-wrapper sticky"><Spinner isContinuous /></div>}
            
            <div className="d-none d-medium-flex align-items-end py-5">
              <div className="maincol pe-medium-3 pe-large-5">
                <p className="mb-5">Simply build your ideal {config.name} using the configurator below, provide your details in the form, and your preferred dealer will contact you to discuss your next steps.</p>
                <hr className="my-0" />
              </div>
              <div className="sidebar">
                <p className="aui-headline-4"><b>{getModelName(formikProps.values)} price</b></p>
                <PostcodePrice formikProps={formikProps} getPrice={getPrice} prices={prices} isFetchingPrices={isFetchingPrices} />
              </div>
            </div>
            
            <div className="d-medium-flex">
              <div className="maincol pe-medium-3 pe-large-5">
                <div className="section d-medium-flex">
                  <div className="section__main ps-medium-3 ps-large-5 mb-3 mb-medium-0">
                    <HeroCarousel
                      stndRenderCodes={config.stndRenderCodes}
                      my={config.myNumber.toString()}
                      variant={formikProps.values.variant}
                      driveType={formikProps.values.driveType}
                      extColour={formikProps.values.extColour}
                      exterior={config.extColours[formikProps.values.extColour]}
                      interior={formikProps.values.interior}
                      options={config.options}
                      selectedOptions={formikProps.values.options}
                      setPayloadImg={setPayloadImg}
                    />
                    <PaintPicker
                      extColour={formikProps.values?.extColour}
                      interior={formikProps.values.interior}
                      onChangeExt={(v) => {
                        formikProps.setFieldValue("extColour", v);
                        getPrice({...formikProps.values, extColour: v});
                        window.dataLayer.push({
                          'event': 'Q4 Reservation',
                          'eventAction': 'Vehicle Configurator',
                          'eventLabel': 'Colour Selected',
                          'colour': paintMap[v]
                        });
                      }}
                      onChangeInt={(v) => {
                        formikProps.setFieldValue("interior", v);
                        window.dataLayer.push({
                          'event': 'Q4 Reservation',
                          'eventAction': 'Vehicle Configurator',
                          'eventLabel': 'Interior Selected',
                          'interior': paintMap[v]
                        });
                      }}
                      extColours={config.extColours}
                      intOptions={config.interiors[formikProps.values.variant + formikProps.values.driveType]}
                    />
                  </div>
                  <div className="sidebar order-medium-first">
                    <ExteriorOptions formikProps={formikProps} variants={config.variants} driveTypes={config.driveTypes} options={config.options} getPrice={getPrice} onChangeModel={onChangeModel} />
                  </div>
                </div>
                
                <hr className="my-5" />
                
                <StandardEquipment
                  config={config}
                  modelName={getModelName(formikProps.values)}
                  modelCode={formikProps.values?.variant + formikProps.values?.driveType}
                  isMobile={isMobile}
                  isIpad={isIpad}
                />
                
                <hr className="my-5 d-medium-none" />
              </div>
              
              <div className="sidebar mb-5 mb-medium-0">
                <p className="aui-headline-4"><b>My details</b></p>
                <FormField name="firstName" label="First name*" formikProps={formikProps} />
                <FormField name="lastName" label="Last name*" formikProps={formikProps} />
                <FormField name="email" label="Email address*" formikProps={formikProps} trim />
                <FormField name="mobile" label="Mobile*" formikProps={formikProps} formatValue={formatMobile} />
                <PostcodePrice formikProps={formikProps} getPrice={getPrice} isOnlyPostcode />
                <DealerByPostcodeModule {...formikProps} />
                <FormField name="contactRegardingModel" type="checkbox" label={(<span className="equipment-desc">I would like to be contacted by a dealer regarding my selected model(s).*</span>)} formikProps={formikProps} />
                <FormField name="marketingOptIn" type="checkbox" label={(<span className="equipment-desc">I would like to be contacted by Audi and Audi Dealers with occasional communications on products, offers, news and events.</span>)} formikProps={formikProps} />
                <p className="mb-3 d-none d-medium-block">
                  <Button type="submit" variant="primary" isStretched onClick={scrollToError}>Submit</Button>
                </p>
                <Disclaimer />
                <p className="aui-small">All fields marked with * are mandatory</p>
                {formikProps.values?.dealer && 
                  <p className="mt-3">For assistance, please contact <b>{formikProps.values.dealer.dealerName}</b><br />Phone: <a href={`tel:${formikProps.values.dealer.phone?.replace(/\D/g, "")}`} className="aui-textlink">{formikProps.values.dealer.phone}</a></p>
                }
              </div>
            </div>
          </div>
          </div>
          
          <div className="aui-color-gray85">
            <div className="app-outer">
            <div className="app-inner aui-small py-5">
              <p><sup>1</sup> Manufacturer's recommended driveaway price (MRDP) displayed for new MY25 Audi Vehicles. MRDP is an estimated price which includes the recommended retail price, 12 months private registration, 12 months compulsory third party insurance (CTP), an estimated dealer delivery charge, stamp duty and other applicable statutory charges that may apply such as Luxury Car Tax (LCT) for private use vehicles above a certain threshold. The MRDP displayed is based on information available at the time the price was calculated and published and does not constitute a binding quote or an offer of sale. The actual driveaway price will be agreed with the selling Audi Dealer. Actual prices and stock availability will vary between locations and dealers, and on-road costs will vary according to individual circumstances. Please contact your local Audi Dealer to confirm your individual price and vehicle lead-time. While stocks last.</p>
              <p><sup>2</sup> Manufacturers List Price - excluding dealer delivery charges, statutory charges and optional extras.</p>
              <p><sup>3</sup> Recommended Dealer Delivery – Dealer delivery fee recommended by Audi Australia. Dealers may charge a different dealer delivery fee, which may be higher or lower.</p>
              <p><sup>4</sup> Registration fees are in accordance with the applicable state/territory legislation, fitted with a premium licence plate (where applicable) and based on private ownership.</p>
              <p className="mt-3">Statutory charges current as at {TODAY.getDate()}-{TODAY.getMonth() + 1}-{TODAY.getFullYear()}.</p>
            </div>
            </div>
          </div>
          
          <div className="sticky-footer py-3 d-medium-none">
            <div className="app-inner">
              <div className="row mb-4">
                <div className="col-12 col-small-6 d-flex flex-column justify-content-end"><p className="aui-headline-4" style={{lineHeight: "28px"}}><b>{getModelName(formikProps.values)} price</b></p></div>
                <div className="col-12 col-small-6 d-flex flex-column justify-content-end">
                  <PostcodePrice formikProps={formikProps} getPrice={getPrice} prices={prices} isFetchingPrices={isFetchingPrices} />
                </div>
              </div>
              <p className="mb-1">
                <Button type="submit" variant="primary" isStretched onClick={scrollToError}>Submit</Button>
              </p>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Reservation;
